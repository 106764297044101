import { Provider as DsxProvider } from '@dsx/react';
import type { LinkSourceProps, LinkSourceRecord } from '@dsx/react';
import { forwardRef } from 'react';
import styles from './App.module.css';
import {
	Outlet,
	Route,
	Link as RouterLink,
	RouterProvider,
	createBrowserRouter,
	createRoutesFromElements,
} from 'react-router-dom';
import { useUtmFormTracking } from './hooks/UseUtmFormTracking';
import { ErrorPage } from './pages/ErrorPage';
import {
	MagnoliaPage,
	magnoliaPageLoader,
	assetCategoryPageLoader,
	legalCategoryPageLoader,
	insightCategoryPageLoader,
	resourceCategoryPageLoader,
} from './pages/MagnoliaPage';
import {
	CollateralLibraryAssetDetailPage,
	collateralLibraryAssetDetailPageLoader,
} from './pages/CollateralLibraryDetailPage/CollateralLibraryAssetDetailPage';
import {
	StoryDetailPage,
	resourceDetailLoader,
	insightDetailLoader,
	legalDetailLoader,
} from './pages/StoryDetailPage/StoryDetailPage';
import { TopNavDs } from './components/TopNavDs';
import { FooterDs } from './components/FooterDs';
import './assets/pdf/17877-TELS-sp-tech-overview-sheet-LoResFin.pdf';
import './assets/pdf/TBS-Interior-Service-Provider-Expectations.pdf';
import './assets/robots/robots.txt';
import { useScrollManagement } from './hooks/useScrollManagement';

const linkSources = {
	default: forwardRef<HTMLAnchorElement, LinkSourceProps>((props, ref) => (
		<RouterLink ref={ref} {...props} to={props.href || ''} />
	)),
	external: forwardRef<HTMLAnchorElement, LinkSourceProps>((props, ref) => (
		<a ref={ref} {...props} />
	)),
	none: forwardRef<HTMLSpanElement, LinkSourceProps>((props, ref) => (
		<span ref={ref} {...props} />
	)),
} as LinkSourceRecord;

const router = createBrowserRouter(
	createRoutesFromElements(
		<Route element={<RootLayout />}>
			<Route
				path="/resources/categories"
				element={<MagnoliaPage />}
				loader={magnoliaPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/resources/categories/*"
				element={<MagnoliaPage />}
				loader={resourceCategoryPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/resources/:storyId"
				element={<StoryDetailPage />}
				loader={resourceDetailLoader}
				errorElement={<ErrorPage />}
			/>

			<Route
				path="/insights/categories"
				element={<MagnoliaPage />}
				loader={magnoliaPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/insights/categories/*"
				element={<MagnoliaPage />}
				loader={insightCategoryPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/insights/:storyId"
				element={<StoryDetailPage />}
				loader={insightDetailLoader}
				errorElement={<ErrorPage />}
			/>

			<Route
				path="/legal/categories"
				element={<MagnoliaPage />}
				loader={magnoliaPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/legal/categories/*"
				element={<MagnoliaPage />}
				loader={legalCategoryPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/legal/:storyId"
				element={<StoryDetailPage />}
				loader={legalDetailLoader}
				errorElement={<ErrorPage />}
			/>

			<Route
				path="/collateral-library/categories"
				element={<MagnoliaPage />}
				loader={magnoliaPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/collateral-library/categories/*"
				element={<MagnoliaPage />}
				loader={assetCategoryPageLoader}
				errorElement={<ErrorPage />}
			/>
			<Route
				path="/collateral-library/:assetId"
				element={<CollateralLibraryAssetDetailPage />}
				loader={collateralLibraryAssetDetailPageLoader}
				errorElement={<ErrorPage />}
			/>

			<Route
				path="*"
				element={<MagnoliaPage />}
				loader={magnoliaPageLoader}
				errorElement={<ErrorPage />}
			/>
		</Route>
	)
);

export function App() {
	useUtmFormTracking();
	return (
		<DsxProvider locale="en-US" linkSources={linkSources}>
			<RouterProvider router={router} />
		</DsxProvider>
	);
}

function RootLayout() {
	useScrollManagement();
	return (
		<div className={styles['site-container']}>
			<TopNavDs />
			<main>
				<Outlet />
			</main>
			<FooterDs />
		</div>
	);
}
