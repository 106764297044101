import { Helmet } from 'react-helmet';

interface SeoMetaProps {
	canonicalUrl?: string;
	description?: string;
	noIndex?: boolean;
	ogImageAsset: string;
	schemaOrg?: string;
	windowTitle?: string;
}

const SeoMeta = ({
	canonicalUrl,
	description,
	ogImageAsset,
	noIndex,
	schemaOrg,
	windowTitle,
}: SeoMetaProps) => {
	const metaTitle = windowTitle + ' | Direct Supply';
	const robotsMetaContent = noIndex ? 'noindex, nofollow' : 'index, follow';
	const pageUrl = window.location.href;

	return (
		<Helmet>
			<title>{metaTitle}</title>
			{description && (
				<meta
					name="description"
					content={`${description} | Direct Supply`}
				/>
			)}
			<meta name="robots" content={robotsMetaContent} />
			{canonicalUrl && <link rel="canonical" href={canonicalUrl} />}
			<meta property="og:type" content="website" />
			<meta property="og:site_name" content="Direct Supply" />
			{pageUrl && <meta property="og:url" content={pageUrl} />}
			{windowTitle && <meta property="og:title" content={metaTitle} />}
			{description && (
				<meta
					property="og:description"
					content={`${description} | Direct Supply`}
				/>
			)}
			{ogImageAsset && (
				<meta name="image" property="og:image" content={ogImageAsset} />
			)}
			{schemaOrg && (
				<script type="application/ld+json">{JSON.stringify(schemaOrg)}</script>
			)}
		</Helmet>
	);
};

export { SeoMeta };
